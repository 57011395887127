import {
  Create, Datagrid, DateField, DateInput, DateTimeInput, DeleteButton, EditButton, Identifier, List, NumberField, RaRecord, ReferenceInput, SaveButton, SelectInput,
  Show, TextField, ReferenceField,
  SimpleShowLayout, Toolbar, required,
  useRecordContext
} from 'react-admin';

import { Edit, SimpleForm } from 'react-admin';
import { FC } from 'react';
import PercentNumberInput from '../../common_modules/components/PercentNumberInput';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import PercentNumberField from '../../common_modules/components/PercentNumberField';
import { Box } from '@mui/material';

export const DefaultRewardList = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <PercentNumberField source="feeSystemReward" />
        <PercentNumberField source="partnerReward" />
        <DateField source="operationStartDate" />
        <EditButton />
      </Datagrid>
    </List >
  )
}

type DefaultRewardFormContentsProps = { isEdit: boolean }
const DefaultRewardFormContents: FC<DefaultRewardFormContentsProps> = () => {
  return (
    <>
      <SimpleShowLayout sx={{ paddingLeft: '0' }}>
        <ReferenceField source="clientId" reference="clients" link={false} label="resources.default-rewards.fields.clientName">
          <TextField source="name" label="resources.default-rewards.fields.clientName" />
        </ReferenceField>
      </SimpleShowLayout>
      <PercentNumberInput source="feeSystemReward" validate={required()} />
      <PercentNumberInput source="partnerReward" validate={required()} />
      <DateInput source="operationStartDate" />
    </>
  );
}

const redirectUrl = (resource?: string, id?: Identifier, data?: Partial<RaRecord>) => {
  return `clients/${data?.clientId}/1`
}

const CustomDeleteButton = () => {
  const record = useRecordContext();
  return (
    <DeleteButton
      confirmTitle={`デフォルト報酬設定を削除`}
      confirmContent="本当に削除しますか？"
      redirect={`/clients/${record?.clientId}/1`}
    />
  );
};

const CustomEditToolbar = () => (
  <Toolbar>
    <SaveButton />
    <Box flex="1" />
    <CustomDeleteButton />
  </Toolbar>
);

export const DefaultRewardEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit mutationMode='pessimistic' mutationOptions={{ onError: handleError }} redirect={redirectUrl}>
      <SimpleForm sx={{ maxWidth: '600px' }} toolbar={<CustomEditToolbar />}>
        <DefaultRewardFormContents isEdit={true} />
      </SimpleForm >
    </Edit >
  )
}

export const DefaultRewardCreate = () => {
  const handleError = useErrorHandler();
  return (
    <Create redirect={redirectUrl} mutationOptions={{ onError: handleError }}>
      <SimpleForm defaultValues={{ status: 'pending' }} sx={{ maxWidth: '600px' }}>
        <SimpleShowLayout sx={{ paddingLeft: '0' }}>
          <ReferenceField source="clientId" reference="clients" link={false} label="resources.default-rewards.fields.clientName">
            <TextField source="name" label="resources.default-rewards.fields.clientName" />
          </ReferenceField>
        </SimpleShowLayout>
        <PercentNumberInput source="feeSystemReward" validate={required()} />
        <PercentNumberInput source="partnerReward" validate={required()} />
        <DateInput source="operationStartDate" validate={required()} />
      </SimpleForm>
    </Create >
  )
}

export const DefaultRewardShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <PercentNumberField source="feeSystemReward" />
        <PercentNumberField source="partnerReward" />
        <DateField source="operationStartDate" />
      </SimpleShowLayout>
    </Show >
  )
}
