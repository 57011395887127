import {
  Edit, TextInput,
  SimpleForm, ImageInput,
  ImageField,
  required
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import SaveOnlyToolbar from '../../common_modules/components/SaveOnlyToolbar';

export const CompanySettingsEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit title="会社設定" mutationMode='pessimistic' mutationOptions={{ onError: handleError }} resource='company-settings' id={1} redirect={false} >
      <SimpleForm toolbar={<SaveOnlyToolbar />} sx={{ maxWidth: '600px' }}>
        <TextInput source="companyName" fullWidth validate={required()} />
        <TextInput source="companyZip" validate={required()} />
        <TextInput source="companyAddress" fullWidth validate={required()} />
        <TextInput source="companyAddress2" fullWidth validate={required()} />
        <TextInput source="companyTel" validate={required()} />
        <TextInput source="companyFax" validate={required()} />
        <TextInput source="companyInvoice" validate={required()} />
        <Box display="flex" justifyContent="flex-start">
          <ImageInput source="companySignature" label="社印" accept="image/png">
            <ImageField source="src" title="社印" />
          </ImageInput>
        </Box>
        <Typography variant="caption" color="textSecondary">
          社印はPNG形式で、ファイルサイズは最大512kBまでです。
        </Typography>
      </SimpleForm>
    </Edit >
  );
};

export default CompanySettingsEdit;
