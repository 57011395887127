import { Translate } from "react-admin";

// 元の関数
export function getLocalizableChoices(translate: Translate, key: string, choiceIds: number[]) {
    return choiceIds.map(choiceId => ({
        id: choiceId,
        name: translate(`${key}.${choiceId}`)
    }));
}

// 新しい関数
export function getLocalizableChoicesByRange(translate: Translate, key: string, start: number, end: number) {
    const choiceIds = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    return choiceIds.map(choiceId => ({
        id: choiceId,
        name: translate(`${key}.${choiceId}`)
    }));
}
