import {
  Create,
  CreateButton,
  DateField, EditButton,
  EmailField, List, NumberField, ReferenceManyField, SelectInput,
  Show,
  SimpleShowLayout,
  TabbedForm,
  TopToolbar,
  TextField, required, useRecordContext, useTranslate,
  ImageInput,
  ImageField,
  RaRecord,
  useNotify,
  useDataProvider,
  useRedirect,
  NumberInput,
  CreateBase,
  EditBase,
  useRefresh
} from 'react-admin';
import Resizer from 'react-image-file-resizer';
import styled from '@emotion/styled';
import { Button, Stack, Typography, Box, alpha } from '@mui/material';
import AddStoreIcon from '@mui/icons-material/AddBusiness';
import EnumTextField from '../../common_modules/components/EnumTextField';
import { Edit, SimpleForm, TextInput, SaveButton, DeleteButton, Toolbar } from 'react-admin';
import { getLocalizableChoices, getLocalizableChoicesByRange } from '../../common_modules/utils/localizeUtils';
import PaymentDateTextField from '../../common_modules/components/PaymentDateTextField';
import NumericRadioButtonGroupInput from '../../common_modules/components/NumericRadioButtonGroupInput';
import { Choice, CLOSING_DATE, CLOSING_MONTH, PREFECTURE_LIST } from '../../common_modules/constants/choices';
import { FC, useEffect, useState } from 'react';
import PercentNumberInput from '../../common_modules/components/PercentNumberInput';
import useErrorHandler from '../../common_modules/hooks/useErrorHandler';
import PercentNumberField from '../../common_modules/components/PercentNumberField';
import FlexibleListLayout from '../../common_modules/components/FlexibleListLayout';
import { useFormContext, useWatch } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import YenField from '../../common_modules/components/YenField';
import NumericSelectRadioButtonGroupInput from '../../common_modules/components/NumericSelectRadioButtonGroupInput';

const AddressButton = styled(Button)(() => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

const CustomCreateButton = () => <CreateButton label="ECサイト追加" icon={<AddStoreIcon />} />;

const CustomDeleteButton = () => {
  const record = useRecordContext();

  return <DeleteButton confirmTitle={`${record.name} を削除`} confirmContent="本当に削除しますか？" />;
};

const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

export const ClientList = () => {
  const translate = useTranslate();
  return (
    <List
      exporter={false}
      actions={<ListActions />}
      sort={{ field: 'applyStatus', order: 'ASC' }}
    >
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <EnumTextField source="applyStatus" translationKeyPrefix={'values.applyStatus'} label={translate('resources.clients.fields.applyStatus')} />
        <TextField source="name" label={translate('resources.clients.fields.name')} />
        <EnumTextField source="accountInvalidFlag" translationKeyPrefix={'values.accountInvalidFlag'} label={translate('resources.clients.fields.accountInvalidFlag')} />
      </FlexibleListLayout>
    </List>
  )
};
const resizeImageToBase64 = (
  file: File | null,
  maxWidth: number,
  maxHeight: number,
  format: string = 'PNG',
  quality: number = 100
): Promise<string | null> => {
  return new Promise((resolve) => {
    if (!file) {
      resolve(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const scaleRatio = Math.min(widthRatio, heightRatio, 1);

        if (scaleRatio === 1) {
          // リサイズ不要
          resolve(event.target?.result as string);
        } else {
          const resizeWidth = img.width * scaleRatio;
          const resizeHeight = img.height * scaleRatio;

          Resizer.imageFileResizer(
            file,
            resizeWidth,
            resizeHeight,
            format,
            quality,
            0,
            (resizedImage) => {
              resolve(resizedImage as string);
            },
            'base64'
          );
        }
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

export const ClientEdit = () => {
  const handleError = useErrorHandler();
  const redirect = useRedirect();
  const notify = useNotify();
  const handleTransform = async (data: Record<string, any>) => {
    // 現在の画像データを保持
    const currentStoreLogo = data.storeLogoThumbnail?.src || null;
    const currentBannerImage = data.bannerImage?.src || null;

    // リサイズ処理を実行
    const storeLogoFile = data.storeLogoThumbnail?.rawFile || null;
    const bannerImageFile = data.bannerImage?.rawFile || null;

    const resizedStoreLogo = await resizeImageToBase64(storeLogoFile, 180, 150, 'PNG');
    const resizedBannerImage = await resizeImageToBase64(bannerImageFile, 500, 500, 'PNG');

    return {
      ...data,
      storeLogoThumbnail: resizedStoreLogo || currentStoreLogo,
      bannerImage: resizedBannerImage || currentBannerImage
    };
  };
  return (
    <Edit mutationMode='pessimistic' mutationOptions={{
      onError: handleError, onSuccess: () => {
        notify('更新しました', { type: 'info' });
        redirect('list', 'clients');
      }
    }} transform={handleTransform}>
      <SimpleForm sx={{ maxWidth: '600px' }} toolbar={false}>
        <ClientFormContents isEdit={true} />
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 2, width: '100%' }}>
          <SaveButton />
          <CustomDeleteButton />
        </Stack>
      </SimpleForm>
    </Edit >
  );
};

export const ClientCreate = () => {
  const handleError = useErrorHandler();
  const redirect = useRedirect();
  const notify = useNotify();
  const handleTransform = async (data: Record<string, any>) => {
    // 現在の画像データを保持
    const currentStoreLogo = data.storeLogoThumbnail?.src || null;
    const currentBannerImage = data.bannerImage?.src || null;

    // リサイズ処理を実行
    const storeLogoFile = data.storeLogoThumbnail?.rawFile || null;
    const bannerImageFile = data.bannerImage?.rawFile || null;

    const resizedStoreLogo = await resizeImageToBase64(storeLogoFile, 180, 150, 'PNG');
    const resizedBannerImage = await resizeImageToBase64(bannerImageFile, 500, 500, 'PNG');

    return {
      ...data,
      accountInvalidFlag: 1,
      storeLogoThumbnail: resizedStoreLogo || currentStoreLogo,
      bannerImage: resizedBannerImage || currentBannerImage
    };
  };

  return (
    <Create
      title="ECサイト追加"
      mutationOptions={{
        onSuccess: (data: any) => {
          notify(`${data.name}を登録しました`, { type: 'info' });
          redirect('list', 'clients');
        },
        onError: handleError,
      }}
      transform={handleTransform}
    >
      <SimpleForm sx={{ maxWidth: '600px' }}>
        <ClientFormContents isEdit={false} />
      </SimpleForm>
    </Create>
  );
};

type FormProps = { isEdit: boolean }

const ClientFormContents: FC<FormProps> = ({ isEdit }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { setValue, getValues } = useFormContext(); // useFormContext でフォームの値とセット関数を取得

  // 住所取得処理
  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  const handleGetDefaultReward = async () => {
    try {
      const url = `/admins/other-settings/1`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('systemUsageFee', data.defaultSystemUsageFee);
      setValue('feeSystemReward', data.defaultFeeSystemReward);
      setValue('partnerReward', data.defaultPartnerReward);
    } catch (e) { }
  }

  useEffect(() => {
    if (!isEdit) {
      handleGetDefaultReward();
    }
  }, []);

  const ecSiteType = getLocalizableChoices(translate, `values.ecSiteType`, [1]);
  const bankAccountType = getLocalizableChoicesByRange(translate, `values.bankAccountType`, 1, 2);
  const accountInvalidFlag = getLocalizableChoicesByRange(translate, `values.accountInvalidFlagWithoutTemporaryregistration`, 0, 1);

  return (
    <>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="email" fullWidth validate={required()} />
      <TextInput source="tel" validate={required()} />
      <Stack direction="row" spacing={2}>
        <TextInput
          source="zipCode"
          label={translate('resources.registers.fields.zipCode')}
          validate={required()}
          helperText={'ハイフンなし'}
        />
        <AddressButton variant="contained" color="primary" onClick={handleGetAddress}>
          住所を入力
        </AddressButton>
      </Stack>
      <SelectInput source="prefecture" validate={required()} choices={PREFECTURE_LIST} />
      <TextInput source="city" validate={required()} />
      <TextInput source="address1" validate={required()} />
      <TextInput source="address2" />
      <TextInput source="ecSiteUrl" fullWidth type="url" validate={required()} />
      <NumericRadioButtonGroupInput source="ecSiteType" choices={ecSiteType} validate={required()} />
      <SelectInput source="closingDate" choices={CLOSING_DATE} validate={required()} />
      <SelectInput source="invoiceDate" choices={CLOSING_DATE} validate={required()} />
      <NumericRadioButtonGroupInput source="plannedPaymentMonth" choices={CLOSING_MONTH} validate={required()} />
      <SelectInput source="plannedPaymentDate" choices={CLOSING_DATE} validate={required()} />
      <NumberInput source="systemUsageFee" validate={required()}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />

      {!isEdit ? (
        <>
          <PercentNumberInput source="feeSystemReward" validate={required()} />
        </>
      ) : (
        <>
          <PercentNumberInput source="currentFeeSystemReward" validate={required()} />
        </>
      )}
      <ImageInput source="storeLogoThumbnail" label="ストア画像" accept="image/png">
        <ImageField source="src" title="ストア画像" />
      </ImageInput>
      <Typography variant="caption" color="textSecondary">
        ストア画像はPNG形式でアップロードしてください。
        <br />
        幅180px、高さ150px以下にリサイズされます。
      </Typography>
      <ImageInput source="bannerImage" label="バナー画像" accept="image/png,image/jpeg">
        <ImageField source="src" title="バナー画像" />
      </ImageInput>
      <Typography variant="caption" color="textSecondary">
        バナー画像はJPEG/PNG形式でアップロードしてください。
        <br />
        幅500px、高さ500px以下にリサイズされます。
      </Typography>
      <Box sx={{ mt: 3 }}>
        {isEdit && (
          <NumericRadioButtonGroupInput
            source="accountInvalidFlag"
            choices={accountInvalidFlag}
            validate={required()}
          />
        )}
      </Box>
    </>
  );
}


const ShowCustomToolbar = (props: any) => {
  return (
    <Box display="flex" mt={2}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        disabled={props.loading}
      >
        送信
      </Button>
    </Box>
  )
};

const ApproveFormContents = () => {
  const applyStatus = useWatch<{ applyStatus: number }>({ name: 'applyStatus' });
  const translate = useTranslate();
  const { setValue } = useFormContext(); // useFormContext でフォームの値とセット関数を取得
  const dataProvider = useDataProvider();

  const handleGetDefaultReward = async () => {
    try {
      const url = `/admins/other-settings/1`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('systemUsageFee', data.defaultSystemUsageFee);
      setValue('feeSystemReward', data.defaultFeeSystemReward);
    } catch (e) { }
  }

  useEffect(() => {
    handleGetDefaultReward();
  }, []);

  const APPROVE_TYPE: Choice[] = [
    { id: 2, name: '承認' },
    { id: 3, name: '却下' }
  ];

  return (
    <>
      <NumericRadioButtonGroupInput
        source="applyStatus"
        choices={APPROVE_TYPE}
        label={translate('resources.clients.fields.applyStatusSelect')}
        validate={required()}
      />

      <NumberInput source="systemUsageFee" validate={required()}
        disabled={applyStatus === 3}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />
      <PercentNumberInput source="feeSystemReward" disabled={applyStatus === 3} validate={required()} />
    </>
  );
}

const ApproveForm = () => {
  const record = useRecordContext();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState<boolean>(false);
  const refresh = useRefresh();

  if (!record) return null;

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const sendData = {
        applyStatus: data['applyStatus'],
        systemUsageFee: data['systemUsageFee'],
        feeSystemReward: data['feeSystemReward'],
        partnerReward: data['partnerReward']
      };
      const status = sendData.applyStatus;
      const url = `/admins/clients/${record.id}/approve`;
      await dataProvider.register(sendData, url)

      const message = status === 2 ? '承認しました。' : '却下しました。';
      notify(message, { type: 'info' });
      redirect('/clients');
      refresh();

    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {record.applyStatus == 1 &&
        <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2} ml={2}>
          <Typography variant="body1" gutterBottom>
            上記の申請内容を確認して、承認または却下を選択してください。<br />
            承認の場合は、システム使用料を設定してください。
          </Typography>

          <SimpleForm
            toolbar={<ShowCustomToolbar loading={loading} />}
            onSubmit={handleSubmit}
          >
            <ApproveFormContents />
          </SimpleForm>
        </Box >
      }
    </>
  );
}

export const ClientShowContents = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="tel" />
      <TextField source="zipCode" />
      <TextField source="prefecture" />
      <TextField source="city" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="ecSiteUrl" />
      <EnumTextField source="ecSiteType" translationKeyPrefix={'values.ecSiteType'} />
      <PaymentDateTextField source="closingDate" />
      <PaymentDateTextField source="invoiceDate" />
      <EnumTextField source="plannedPaymentMonth" translationKeyPrefix="values.paymentMonth" />
      <PaymentDateTextField source="plannedPaymentDate" />
      {record.applyStatus == 2 && (
        <YenField source="systemUsageFee" />
      )}
      {record.applyStatus == 2 && (
        <PercentNumberField source="currentFeeSystemReward" />
      )}
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Typography variant="caption" color="textSecondary">ストア画像</Typography>
        <ImageField source="storeLogoThumbnail.src" />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Typography variant="caption" color="textSecondary">バナー画像</Typography>
        <ImageField source="bannerImage.src" />
      </Box>
      <EnumTextField source="accountInvalidFlag" translationKeyPrefix={'values.accountInvalidFlag'} />
    </SimpleShowLayout>
  )
}

export const ClientShow = () => {
  return (
    <>
      <Show>
        <ClientShowContents />
        <ApproveForm />
      </Show >
    </>
  )
}