import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import baseTheme, { baseResponsiveFontSizes } from './common_modules/theme/BaseTheme';

let theme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#2A5D87'
    },
    secondary: {
      main: '#9FAAB3',
      contrastText: '#ffffff'
    }
  }
});
theme = baseResponsiveFontSizes(theme);

export default theme;
