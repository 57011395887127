import {
  DateField,
  Edit,
  EditButton,
  EmailField,
  List, ReferenceField, Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  WrapperField,
  useRecordContext,
  useTranslate,
  TopToolbar,
  CreateButton,
  Create,
  DeleteButton,
  SelectInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useDataProvider
} from 'react-admin';
import styled from '@emotion/styled';
import { Button, Stack, Link } from '@mui/material';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { FC } from 'react';
import EnumTextField from '../common_modules/components/EnumTextField';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import { ACCOUNT_STATUS, BANK_ACCOUNT_TYPE, PARTNER_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import ZoomableImageField from '../common_modules/components/ZoomableImageField';
import { useWatch } from "react-hook-form";
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import AddIcon from '@mui/icons-material/Add';
import { Box, useMediaQuery } from '@mui/material';
import DragImageInput from '../common_modules/components/DragImageInput';
import { useFormContext } from 'react-hook-form';
import {
  LabeledEnumTextField,
  LabeledTextField,
} from '../common_modules/components/LabeledFieldComponents';

const AddressButton = styled(Button)(() => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

const CustomCreateButton = () => <CreateButton label="パートナー追加" icon={<AddIcon />} />;

const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

const CustomDeleteButton = () => {
  const record = useRecordContext();
  return <DeleteButton confirmTitle={`${record.name} を削除`} confirmContent="本当に削除しますか？" />;
};

const CustomEditToolbar = () => (
  <Toolbar>
    <SaveButton />
    <Box flex="1" />
    <CustomDeleteButton />
  </Toolbar>
);



export const PartnerList = () => {
  const translate = useTranslate();
  return (
    <List exporter={false} actions={<ListActions />}>
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label={translate('resources.partners.fields.name')} />
        <EnumTextField source="partnerType" translationKeyPrefix={'values.partnerType'} label={translate('resources.partners.fields.partnerType')} />
        <DateField source="joinDate" label={translate('resources.partners.fields.joinDate')} />
        <EmailField source="email" />
        <EnumTextField source="accountInvalidFlag" translationKeyPrefix={'values.accountInvalidFlag'} />
        <EditButton />
      </FlexibleListLayout>
    </List >
  )
};

type FormProps = { isEdit?: boolean }
const ACCOUNT_STATUS_EDIT = ACCOUNT_STATUS.filter((status) => status.id !== 2);

const PartnerFormContents: FC<FormProps> = (props: FormProps) => {
  const translate = useTranslate();
  const partnerType = useWatch<{ partnerType: number }>({ name: 'partnerType' });
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { setValue, getValues } = useFormContext(); // useFormContext でフォームの値とセット関数を取得

  // 住所取得処理
  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  return (
    <>
      {props.isEdit ? (
        <LabeledEnumTextField source="partnerType" translationKeyPrefix={'values.partnerType'} label={translate('resources.partners.fields.partnerType')} />
      ) : (
        <NumericRadioButtonGroupInput source="partnerType" choices={PARTNER_TYPE} validate={required()} />
      )}
      {partnerType === 2 && (
        props.isEdit ? (
          <LabeledTextField source="name" label={translate('resources.partners.fields.name')} />
        ) : (
          <TextInput source="name" validate={required()} label={translate('resources.partners.fields.name')} />
        )
      )}
      {props.isEdit ? (
        <TextInput source="lastName" validate={required()} label={partnerType === 2 ? translate('resources.registers.fields.picLastName') : translate('resources.registers.fields.lastName')} />
      ) : (
        <TextInput source="lastName" validate={required()} label={partnerType === 2 ? translate('resources.registers.fields.picLastName') : translate('resources.registers.fields.lastName')} />
      )}

      {props.isEdit ? (
        <TextInput source="firstName" validate={required()} label={partnerType === 2 ? translate('resources.registers.fields.picFirstName') : translate('resources.registers.fields.firstName')} />
      ) : (
        <TextInput source="firstName" validate={required()} label={partnerType === 2 ? translate('resources.registers.fields.picFirstName') : translate('resources.registers.fields.firstName')} />
      )}
      <Stack direction="row" spacing={2}>
        <TextInput
          source="zipCode"
          label={translate('resources.registers.fields.zipCode')}
          validate={required()}
          helperText={'ハイフンなし'}
        />
        <AddressButton variant="contained" color="primary" onClick={handleGetAddress}>
          住所を入力
        </AddressButton>
      </Stack>
      <SelectInput source="prefecture" validate={required()} choices={PREFECTURE_LIST} />
      <TextInput source="city" validate={required()} />
      <TextInput source="address1" fullWidth validate={required()} />
      <TextInput source="address2" fullWidth />
      {partnerType === 2 && (
        <TextInput type="tel" source="tel" validate={partnerType === 2 ? required() : undefined} helperText={'ハイフンなし'} />
      )}
      {partnerType === 1 && (
        <TextInput
          type="tel"
          source="mobilePhone"
          validate={partnerType === 1 ? required() : undefined}
          helperText={'ハイフンなし'}
        />
      )}
      <TextInput source="email" fullWidth validate={required()} />
      <TextInput source="website" fullWidth />
      <TextInput source="invoiceNumber" helperText={'T+半角数字13桁'} />
      {partnerType === 2 && (
        props.isEdit ? (
          <LabeledTextField source="dunsNumber" label={translate('resources.registers.fields.dunsNumber')} />
        ) : (
          <TextInput source="dunsNumber" validate={required()} label={translate('resources.registers.fields.dunsNumber')}
            helperText={
              <span>
                D-U-N-Sナンバーの確認は
                <Link href="https://duns-number-jp.tsr-net.co.jp/search/jpn/find_jpn.asp" target="_blank" rel="noopener">
                  こちら
                </Link>
                から。
              </span>
            }
            FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
          />
        )
      )}

      <TextInput type="tel" source="bankNumber" validate={required()} helperText={'半角数字4桁'} />
      <TextInput source="bankName" fullWidth validate={required()} />
      <TextInput type="tel" source="bankBranchNumber" validate={required()} helperText={'半角数字3桁'} />
      <TextInput source="bankBranchName" fullWidth validate={required()} />

      <NumericRadioButtonGroupInput source="bankAccountType" choices={BANK_ACCOUNT_TYPE} validate={required()} />
      <TextInput type="tel" source="bankAccountNumber" validate={required()} helperText={'半角数字7桁'} />
      <TextInput source="bankAccountName" validate={required()} helperText={'カナ（小文字を除く）、英大文字、数字、半角スペース、記号（().-）のみ使用できます。'} FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }} />

      {/* {partnerType === 1 && (
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
          sx={{ width: '100%' }}
          gap={isSmallScreen ? 4 : 3}
        >
          <DragImageInput
            source={'frontImage'}
            accept="image/jpeg"
            maxSize={10000000}
            label={translate('resources.partners.fields.frontImage')}
            labelVariant={'caption'}
            validate={required()}
          />
          <DragImageInput
            source={'backImage'}
            accept="image/jpeg"
            maxSize={10000000}
            label={translate('resources.partners.fields.backImage')}
            labelVariant={'caption'}
            validate={required()}
          />
        </Box>
      )} */}

      {props.isEdit && (
        <NumericRadioButtonGroupInput source="accountInvalidFlag" choices={ACCOUNT_STATUS_EDIT} validate={required()} />
      )}
    </>
  );
}

export const PartnerEdit = () => {
  const handleError = useErrorHandler();
  return (
    <Edit mutationMode='pessimistic' mutationOptions={{ onError: handleError }} >
      <SimpleForm toolbar={<CustomEditToolbar />} sx={{ maxWidth: '600px', width: '100%' }}>
        <PartnerFormContents isEdit={true} />
      </SimpleForm>
    </Edit>
  )
};

export const PartnerCreate = () => {
  const handleError = useErrorHandler();
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Create
      title="パートナー追加"
      mutationOptions={{
        onSuccess: (data: any) => {
          notify(`${data.name}を登録しました`, { type: 'info' });
          redirect('list', 'partners');
        },
        onError: handleError,
      }}

    >
      <SimpleForm sx={{ maxWidth: '600px', width: '100%' }} >
        <PartnerFormContents isEdit={false} />
      </SimpleForm>
    </Create>
  )
};



const PartnerShowContents = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;
  const isCorporation = record.partnerType === 2;

  return (
    <SimpleShowLayout >
      {isCorporation && <TextField source="name" />}
      <TextField source="lastName" />
      <TextField source="firstName" />
      <EnumTextField source="partnerType" translationKeyPrefix={'values.partnerType'} />
      <DateField source="joinDate" />
      <EmailField source="email" />
      {isCorporation && <TextField source="tel" />}
      {!isCorporation && <TextField source="mobilePhone" />}
      <WrapperField label="resources.partners.fields.address" source="prefecture">
        <div>
          <TextField source="zipCode" />
        </div>
        <TextField source="prefecture" />
        <TextField source="city" />
        <TextField source="address1" />
        <TextField source="address2" />
      </WrapperField>
      <TextField source="bankNumber" />
      <TextField source="bankName" />
      <TextField source="bankBranchNumber" />
      <TextField source="bankBranchName" />
      <EnumTextField source="bankAccountType" translationKeyPrefix={'values.bankAccountType'} />
      <TextField source="bankAccountNumber" />
      <TextField source="bankAccountName" />
      <TextField source="invoiceNumber" emptyText='common.none' />

      {isCorporation && <TextField source="dunsNumber" />}
      <ReferenceField source="parentPartnerId" reference="partners" emptyText='common.none' label={translate('resources.partners.fields.parentPartner')}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="website" />
      <EnumTextField source="accountInvalidFlag" translationKeyPrefix={'values.accountInvalidFlag'} />
      {/* {!isCorporation && <ZoomableImageField source="frontImage.src" label={`resources.partners.fields.frontImage`} />}
      {!isCorporation && <ZoomableImageField source="backImage.src" label={`resources.partners.fields.backImage`} />} */}
    </SimpleShowLayout>
  )
};

export const PartnerShow = () => {
  return (
    <Show>
      <PartnerShowContents />
    </Show>
  )
};

