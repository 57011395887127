import { Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SidebarHeaderProps {
  title: string;
  onCancel: () => void;
}

const SidebarHeader = ({ title, onCancel }: SidebarHeaderProps) => {
  return (
    <Stack direction="row" p={2}>
      <Typography variant="h6" flex="1">
        {title}
      </Typography>
      <IconButton onClick={onCancel} size="small">
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default SidebarHeader