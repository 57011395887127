import { useEffect, useState, cloneElement, ReactElement } from 'react';
import { downloadCSV, Datagrid, DateField, Identifier, FunctionField, List, TopToolbar, ExportButton, NumberField, ReferenceField, TextField, useDataProvider, useListContext, usePermissions } from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import Button from '@mui/material/Button';
import { useTranslate, useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import {
  EditBase,
  TextInput,
  SimpleForm,
  EditProps
} from 'react-admin';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { PAYMENT_STATUS } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import SidebarHeader from '../common_modules/components/SidebarHeader';
import SaveOnlyToolbar from '../common_modules/components/SaveOnlyToolbar';
import SidebarDrawer from '../common_modules/components/SidebarDrawer';
import InvoiceMonthSelector from './components/InvoiceMonthSelector';
import CustomDatagrid from '../common_modules/components/CustomDatagrid';
import { hasPermission } from '../authProvider';
import { ROLE_EDIT, ROLE_ADMIN, ROLE_SALES } from '../common_modules/constants/define';
import { format } from 'date-fns';
import jsonExport from 'jsonexport/dist';


const LinkToInvoiceButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.invoiceLink !== null;
  return (hasLink
    && (<Button
      size="small"
      color="primary"
      variant="contained"
      sx={{ display: 'inline-flex', alignItems: 'center' }}
      onClick={(event) => {
        event.stopPropagation();
        dataProvider.download(record.invoiceLink);
      }}
    >
      {translate('resources.invoices.fields.invoicePdf')}
    </Button >
    ))
};

const LinkToReceiptButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.receiptLink !== null;
  return (hasLink
    && (<Button
      size="small"
      color="primary"
      variant="contained"
      sx={{ display: 'inline-flex', alignItems: 'center' }}
      onClick={(event) => {
        event.stopPropagation();
        dataProvider.download(record.receiptLink);
      }}
    >
      {translate('resources.invoices.fields.receiptPdf')}
    </Button >
    ))
};

const LinkToInvoiceDetailButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.invoiceLink !== null;
  return (
    hasLink && (
      <Button
        size="small"
        color="primary"
        variant="contained"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={(event) => {
          event.stopPropagation();
          dataProvider.download(record.invoiceDetailLink);
        }}
      >
        {translate('resources.invoices.fields.invoiceDetailPdf')}
      </Button>
    )
  );
};

const ListActionsWithExport = ({ exporter }: { exporter: (records: any[]) => void }) => {
  const { total } = useListContext();
  return (
    <TopToolbar>
      <ExportButton label="CSVダウンロード" exporter={exporter} disabled={total === 0} />
    </TopToolbar>
  );
};

const CustomListNoResults = () => {
  return (
    <Box textAlign="center">
      <InboxIcon sx={{ fontSize: 216, color: '#9e9e9e' }} />
      <Typography variant="h4" color='#9e9e9e' mt={2}>
        売上請求はありません
      </Typography>
    </Box>
  );
};

const handleRowClick = (id: Identifier) => {
  return `/invoices/${id}`;
}

export const InvoiceList = () => {
  const currentMonth = format(new Date(), 'yyyy-MM'); // 現在の年月を取得
  const [selectedDate, setSelectedDate] = useState(currentMonth); // デフォルト値を現在の月に設定
  const [isEmptyData, setIsEmptyData] = useState(false);
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const handleExport = async (records: any[]) => {
    if (!records || records.length === 0) {
      return;
    }
    // クライアントIDの取得
    const clientIds = records.map(record => record.clientId);
    const { data: relatedClients } = await dataProvider.getMany('clients', { ids: clientIds });

    // エクスポートするフィールドを指定
    const exportFields = ['issueDate', 'clientId', 'salesEndDate', 'totalAmount', 'status', 'invoiceLink'];

    // ヘッダーを翻訳
    const keys = exportFields.map(key => key === 'clientId' ? '登録名' : translate(`resources.invoices.fields.${key}`, { _: key }));

    // データの変換
    const dataToExport = records.map(record => {
      const transformedRecord: Record<string, any> = {};
      exportFields.forEach(key => {
        let value = record[key];
        // clientIdの名前を取得して置き換える
        if (key === 'clientId') {
          value = relatedClients.find(client => client.id === record.clientId)?.name || '';
        }
        // salesEndDate フィールドの変換
        if (key === 'salesEndDate' && value) {
          const date = new Date(value);
          value = `${date.getMonth() + 1}月請求`;
        }
        // status フィールドの翻訳
        if (key === 'status') {
          value = translate(`values.depositStatus.${value}`, { _: value });
        }
        transformedRecord[key === 'clientId' ? '登録名' : translate(`resources.invoices.fields.${key}`, { _: key })] = value || '';
      });
      return transformedRecord;
    });

    // JSONをCSVに変換してダウンロード
    jsonExport(dataToExport, { headers: keys }, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      const bom = '\uFEFF';
      const csvWithBom = bom + csv;
      downloadCSV(csvWithBom, '売上請求');
    });
  };

  const handleDateChange = (id: string) => {
    setSelectedDate(id);
  };

  const handleEmptyDataChange = () => {
    setIsEmptyData(true); // データがない場合に状態を変更
  };

  const [isShowList, setShowList] = useState(false);
  const [listSx, setListSx] = useState<any>(undefined);
  const handleTotalChange = (total: number) => {
    setShowList(total > 0);
    if (total === 0) {
      handleEmptyDataChange();
    }
  };

  useEffect(() => {
    const params = isShowList ? { maxWidth: 800 } : undefined;
    setListSx(params);
  }, [isShowList]);

  return (
    <Box>
      <InvoiceMonthSelector onDateChange={handleDateChange} onEmptyData={handleEmptyDataChange} />
      {!isEmptyData ? (
        <List
          sx={listSx}
          title="resources.invoices.name"
          sort={{ field: 'issueDate', order: 'DESC' }}
          actions={<ListActionsWithExport exporter={handleExport} />}
          resource={`invoice-list/${selectedDate || currentMonth}`} // selectedDateが空の場合、デフォルトの月を使用
          exporter={handleExport} // カスタムエクスポーターを指定
        >
          <CustomDatagrid onTotalChange={handleTotalChange} rowClick={handleRowClick} bulkActionButtons={false}>
            <DateField source="issueDate" label="resources.invoices.fields.issueDate" />
            <TextField source="clientName" label="resources.invoices.fields.clientName" />
            <FunctionField source="salesEndDate"
              label="請求"
              render={(record: { salesEndDate: string; }) => `${(new Date(record.salesEndDate)).getMonth() + 1}月請求`}
            />
            <NumberField source="totalAmount" label="resources.invoices.fields.totalAmount" />
            <EnumTextField source="status" label="resources.invoices.fields.status" translationKeyPrefix={'values.depositStatus'} />
            <LinkToInvoiceButton />
            <LinkToReceiptButton />
          </CustomDatagrid>
        </List>
      ) : (
        <List sx={listSx} title="resources.invoices.name">
          <CustomListNoResults />
        </List>
      )}

      <SidebarDrawer path="/invoices/:id" basePath="/invoices">
        {({ id, onCancel }) => (
          <InvoiceEdit id={id} onCancel={onCancel} />
        )}
      </SidebarDrawer>
    </Box>
  );
};
interface Props extends EditProps {
  onCancel: () => void;
}

const InvoiceEdit = ({ id, onCancel }: Props) => {
  const translate = useTranslate();
  const handleError = useErrorHandler();

  // 送信情報を制限する
  const transform = (data: any) => ({
    status: data.status,
    invoiceMemo: data.invoiceMemo,
    depositMemo: data.depositMemo,
  });

  const handleSuccess = () => {
    window.location.reload();
  };

  return (
    <EditBase id={id} mutationMode='pessimistic' mutationOptions={{ onError: handleError, onSuccess: handleSuccess }} transform={transform}>
      <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <SidebarHeader title={translate('resources.invoices.detail')} onCancel={onCancel} />
        <SimpleForm
          sx={{ pt: 0, pb: 0 }}
          toolbar={<SaveOnlyToolbar />}
        >
          <LinkToInvoiceDetailButton />

          <NumericRadioButtonGroupInput source="status" choices={PAYMENT_STATUS} />
          <TextInput
            source="invoiceMemo"
            fullWidth
            multiline
            rows={5}
            InputProps={{
              sx: {
                paddingTop: 1
              },
            }}
          />
          <TextInput source="depositMemo" fullWidth
            multiline
            rows={5}
            InputProps={{
              sx: {
                paddingTop: 1
              },
            }}
          />
        </SimpleForm>
      </Box>
    </EditBase>
  );
};
