import { downloadCSV, DateField, EditBase, EditProps, List, NumberField, SimpleForm, TextField, TextInput, useDataProvider, ExportButton, TopToolbar, useTranslate, useRecordContext } from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import Button from '@mui/material/Button';
import SidebarDrawer from '../common_modules/components/SidebarDrawer';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import SidebarHeader from '../common_modules/components/SidebarHeader';
import { Box } from '@mui/material';
import SaveOnlyToolbar from '../common_modules/components/SaveOnlyToolbar';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { FEEBACK_PAYMENT_STATUS } from '../common_modules/constants/choices';
import { useEffect, useState } from 'react';
import CustomDatagrid from '../common_modules/components/CustomDatagrid';
import jsonExport from 'jsonexport/dist';

const LinkToZenginButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.zenginUrl !== null;
  return (
    hasLink && (
      <Button
        size="small"
        color="primary"
        variant="contained"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={(event) => {
          event.stopPropagation();
          dataProvider.download(record.zenginUrl);
        }}
      >
        {translate('resources.payments.fields.zenginFile')}
      </Button>
    )
  );
};

const ListActions = ({ exporter }: { exporter: (records: any[]) => void }) => (
  <TopToolbar>
    <ExportButton label="CSVダウンロード" exporter={exporter} />
  </TopToolbar>
);

export const PaymentList = () => {
  const [isShowList, setShowList] = useState(false);
  const [listSx, setListSx] = useState<any>(undefined);
  const translate = useTranslate();
  const handleTotalChange = (total: number) => {
    setShowList(total > 0);
  };

  const handleExport = async (records: any[]) => {
    if (!records || records.length === 0) {
      return;
    }
    const sources = ['paymentDate', 'totalAmount', 'paymentGroupStatus', 'memo', 'zenginUrl'];
    const exportFields = sources.map(source => ({
      source,
      label: translate(`resources.payments.fields.${source}`),
    }));

    // データの変換
    const dataToExport = records.map(record => {
      const transformedRecord: Record<string, any> = {};
      exportFields.forEach(({ source }) => {
        let value = record[source];
        if (source === 'paymentDate') {
          value = new Date(value).toISOString().split('T')[0];
        }
        if (source === 'paymentGroupStatus') {
          value = translate(`values.paymentStatus.${value}`);
        }
        transformedRecord[source] = value;
      });
      return transformedRecord;
    });

    // JSONをCSVに変換してダウンロード
    jsonExport(
      dataToExport,
      { headers: exportFields.map(field => field.label) },
      (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        const bom = '\uFEFF'; // BOMを追加
        const csvWithBom = bom + csv;
        downloadCSV(csvWithBom, '支払い');
      }
    );
  };

  useEffect(() => {
    const params = isShowList ? { maxWidth: 800 } : undefined;
    setListSx(params);
  }, [isShowList]);

  return (
    <Box>
      <List sx={listSx} actions={<ListActions exporter={handleExport} />}>
        <CustomDatagrid onTotalChange={handleTotalChange} rowClick="edit" bulkActionButtons={false}>
          <DateField source="paymentDate" />
          <NumberField source="totalAmount" />
          <EnumTextField source="paymentGroupStatus" translationKeyPrefix={'values.paymentStatus'} />
          <TextField source="memo" />
          <LinkToZenginButton />
        </CustomDatagrid>
      </List>
      <SidebarDrawer path="/payments/:id" basePath="/payments">
        {({ id, onCancel }) => (
          <PaymentsEdit id={id} onCancel={onCancel} />
        )}
      </SidebarDrawer>
    </Box>
  );
};

interface Props extends EditProps {
  onCancel: () => void;
}

const PaymentsEdit = ({ id, onCancel }: Props) => {
  const translate = useTranslate();
  const handleError = useErrorHandler();

  // 送信情報を制限する
  const transform = (data: any) => ({
    paymentGroupStatus: data.paymentGroupStatus,
    memo: data.memo,
  });

  return (
    <EditBase id={id} mutationMode="pessimistic" mutationOptions={{ onError: handleError }} transform={transform}>
      <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <SidebarHeader title={translate('resources.payments.detail')} onCancel={onCancel} />
        <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<SaveOnlyToolbar />}>
          <NumericRadioButtonGroupInput source="paymentGroupStatus" choices={FEEBACK_PAYMENT_STATUS} />
          <TextInput source="memo" fullWidth multiline rows={5} />
        </SimpleForm>
      </Box>
    </EditBase>
  );
};
