import {
  Admin,
  Resource, Layout,
  CustomRoutes
} from "react-admin";
import { dataProvider } from "./dataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { AdminUserCreate, AdminUserEdit, AdminUserList, AdminUserShow } from "./resources/AdminUsers";
import { japaneseMessages } from "./i18n/ja";
import authProvider from "./authProvider";
import { ClientCreate, ClientEdit, ClientList, ClientShow } from "./resources/clients/Clients";
import CustomMenu from "./Menu";
import { PartnerCreate, PartnerEdit, PartnerList, PartnerShow } from "./resources/Partners";
import CustomLoginPage from "./common_modules/components/login/CustomLoginPage";
import Analytics from "./common_modules/components/Analytics";
import CompanySettingsEdit from "./resources/settings/CompanySettings";
import PaymentSettingsEdit from "./resources/settings/PaymentSettings";
import TransferSettingsEdit from "./resources/settings/TransferSettings";
import { DefaultRewardCreate, DefaultRewardEdit, DefaultRewardShow } from "./resources/clients/DefaultReward";
import { InvoiceList } from "./resources/Invoices";
import { PaymentList } from "./resources/Payments";
import { OrderList, OrderShow } from "./resources/Orders";
import theme from './theme';
import Dashboard from "./resources/Dashboard";
import CustomAppBar from './common_modules/components/CustomAppBar';
import { Route } from 'react-router-dom';
import CreateStaff from './common_modules/components/CreateStaff';
import SMSAuthentication from './common_modules/components/SMSAuthentication';
import Completion from './common_modules/components/Completion';
import Register from './common_modules/components/Register';
import RegisterError from './common_modules/components/RegisterError';
import ForgotPasswordPage from "./common_modules/components/login/ForgotPasswordPage";
import ResetPasswordSuccessPage from "./common_modules/components/login/ResetPasswordSuccessPage";
import AuthHelper from './common_modules/utils/AuthHelper';
import OtherSettingsEdit from "./resources/settings/OtherSettings";
import ClientInviteUrl from "./resources/ClientInviteUrl"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { jaJP } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
AuthHelper.init(
  import.meta.env.VITE_COGNITO_ENDPOINT,
  import.meta.env.VITE_ADMIN_USER_POOL_ID,
  import.meta.env.VITE_ADMIN_USER_POOL_CLIENT_ID
);

export const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja');

//
// admin-users
// 管理者を作成
//
dayjs.locale('ja');

const layout = (props: any) => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />;
export const App = () => (
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale="ja"
    localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <Admin layout={layout}
      dashboard={Dashboard}
      loginPage={() => (
        <CustomLoginPage
          subtitle="for Administrator"
          backgroundColor="#2A5D87"
          buttonColor="#2A5D87"
        />
      )} i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider}
      theme={theme}
    >
      <CustomRoutes noLayout>
        <Route path="/reset-password" element={<ForgotPasswordPage
          backgroundColor="#9FAAB3"
          buttonColor="#9FAAB3"
        />} />
        <Route path="/reset-complete" element={<ResetPasswordSuccessPage
          backgroundColor="#9FAAB3"
          buttonColor="#9FAAB3"
        />} />
      </CustomRoutes>

      <CustomRoutes noLayout>
        <Route path="/register/admin-staff" element={<Register />} />
        <Route path="/register/admin-staff/create-staff" element={<CreateStaff />} />
        <Route path="/register/admin-staff/smsauth" element={<SMSAuthentication />} />
        <Route path="/register/admin-staff/complete" element={<Completion />} />
        <Route path="/register/admin-staff/error" element={<RegisterError />} />
        <Route path="/register/admin/error" element={<RegisterError />} />
      </CustomRoutes>

      <CustomRoutes>
        {/* <Route path="/analytics" element={<Analytics />} /> */}
        <Route path="/all-analytics" element={<Analytics selectedResultType={1} />} />
        <Route path="/clients-analytics" element={<Analytics selectedResultType={2} />} />
        <Route path="/partners-analytics" element={<Analytics selectedResultType={3} />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="invite-url" element={<ClientInviteUrl />} />
      </CustomRoutes>

      <Resource name="admin-users"
        list={AdminUserList}
        show={AdminUserShow}
        edit={AdminUserEdit}
        create={AdminUserCreate}
      />
      <Resource name="clients"
        list={ClientList}
        show={ClientShow}
        edit={ClientEdit}
        create={ClientCreate}
      />

      <Resource name="default-rewards"
        show={DefaultRewardShow}
        edit={DefaultRewardEdit}
        create={DefaultRewardCreate}
      />

      <Resource name="partners"
        list={PartnerList}
        show={PartnerShow}
        edit={PartnerEdit}
        create={PartnerCreate}
      />

      <Resource name="orders" list={OrderList} show={OrderShow} />
      <Resource name="invoices"
        list={InvoiceList}
      />
      <Resource name="payments"
        list={PaymentList}
      />

      <Resource name="company-settings"
        list={CompanySettingsEdit}
      />
      <Resource name="transfer-settings"
        list={TransferSettingsEdit}
      />
      <Resource name="payment-settings"
        list={PaymentSettingsEdit}
      />
      <Resource name="other-settings"
        list={OtherSettingsEdit}
      />

    </Admin>
  </LocalizationProvider>

);

